

import { Link, Redirect, Route, Router,Switch, useRoute } from 'wouter'

import React from 'react'

import * as contents from './contents'
import { useErrorBoundary } from 'use-error-boundary'

const DEFAULT_COMPONENT_NAME = 'Menu'
//const DEFAULT_COMPONENT_NAME = 'Histoire'

const visibleComponents = Object.entries(contents).reduce(
  (acc, [name, item]) => ({ ...acc, [name]: item }),
  {}
);

function Content() {
  const [match, params] = useRoute('/content/:name')
  console.log("params: ");
  console.log(params);
  //params.name = params.name.replace(".php","")
  let compName = match && params.name && typeof params.name === 'string' ? params.name : DEFAULT_COMPONENT_NAME
    console.log(compName);
  compName = compName.replace(".php","")
  const Component = compName in visibleComponents ? visibleComponents[compName].Component : null

  if (!Component) {
    return null
  }

  return (
      <Component />
  )
}

export const Loading = () => {
  return (
    <div >
         <div>El Mahdia is loding...</div>   
         <div className="loader-fb"></div>  
     
    </div>
  )
}

function Dots() {
  const [match, params] = useRoute('/content/:name')
  if (!match) return null

  return (
    <div  >
      <div  >
        {Object.entries(visibleComponents).map(function mapper([name, _item]) {
          const background = params.name === name ? 'salmon' : '#fff'
          return <Link   key={name} to={`/demo/${name}`} style={{ background }} />
        })}
      </div>
      <div  >{params.name}</div>
    </div>
  )
}

function App() {
 
  return (
    <>
        <React.Suspense fallback={<Loading />}>
        <Switch>
          <Route path="/admin" children={<Redirect to={`/content/Administrateur.php`} />} />    
          <Route path="/chatroom" children={<Redirect to={`/content/ChatRoom.php`} />} /> 
          <Route path="/content/:name">
            <Content />
          </Route>         
          <Route path="/" children={<Redirect to={`/content/${DEFAULT_COMPONENT_NAME}.php`} />} />    
       </Switch>   
        </React.Suspense>
  
    </>
  );
}

export default App;
