import { lazy } from 'react'

const Menu = { Component: lazy(() => import('./Menu')) }
const Histoire = { Component: lazy(() => import('./Histoire')) }
const Arts = { Component: lazy(() => import('./Arts')) }
const Marketplace = { Component: lazy(() => import('./Marketplace')) }
const Administrateur = { Component: lazy(() => import('./Administrateur')) }

const ChatRoom = { Component: lazy(() => import('./ChatRoom')) }
//const Player = { Component: lazy(() => import('./Player')) }
//const Text = { Component: lazy(() => import('./Text')) }
//const Hands = { Component: lazy(() => import('./Hands')) }
//const ControllersEnvMap = { Component: lazy(() => import('./ControllersEnvMap')) }
//const Teleport = { Component: lazy(() => import('./Teleport')) }
//const CameraLinkedObject = { Component: lazy(() => import('./CameraLinkedObject')) }

//export { Interactive, History, HitTest, Player, Text, Hands, Teleport, CameraLinkedObject, ControllersEnvMap }
export { Menu, Histoire, Arts, Marketplace, Administrateur,ChatRoom  }
